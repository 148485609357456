$icomoon-font-family: "npaper" !default;
$icomoon-font-path: "fonts" !default;

$np-np_file_empy: unquote('"\\e96e"');
$np-np_file_check: unquote('"\\e96c"');
$np-np_file_double_check: unquote('"\\e96d"');
$np-filter1: unquote('"\\e96b"');
$np-adm: unquote('"\\e96a"');
$np-help: unquote('"\\e966"');
$np-edit-user: unquote('"\\e968"');
$np-file-view: unquote('"\\e969"');
$np-hourglass: unquote('"\\e967"');
$np-add: unquote('"\\e965"');
$np-Icon-file: unquote('"\\e964"');
$np-New-doc: unquote('"\\e963"');
$np-done-line: unquote('"\\e962"');
$np-close-doc: unquote('"\\e95f"');
$np-company: unquote('"\\e95e"');
$np-menu-duo: unquote('"\\e961"');
$np-menu-trio: unquote('"\\e960"');
$np-eletronic-signature: unquote('"\\e95c"');
$np-digital-signature: unquote('"\\e95d"');
$np-arrow-right: unquote('"\\e95b"');
$np-move-folder: unquote('"\\e95a"');
$np-arrow-left: unquote('"\\e956"');
$np-share-2: unquote('"\\e957"');
$np-share: unquote('"\\e958"');
$np-user-folder: unquote('"\\e959"');
$np-tag: unquote('"\\e945"');
$np-duplicate: unquote('"\\e946"');
$np-edit-2: unquote('"\\e947"');
$np-incoming-box: unquote('"\\e948"');
$np-incoming-mail: unquote('"\\e949"');
$np-locked: unquote('"\\e94a"');
$np-mail-box: unquote('"\\e94b"');
$np-mail-locked: unquote('"\\e94c"');
$np-mail-unlocked: unquote('"\\e94d"');
$np-outgoing-box: unquote('"\\e94e"');
$np-outgoing-mail: unquote('"\\e94f"');
$np-unlocked: unquote('"\\e950"');
$np-stamp: unquote('"\\e951"');
$np-snoozed-mail: unquote('"\\e952"');
$np-sending-mail: unquote('"\\e953"');
$np-readed-mail: unquote('"\\e954"');
$np-printer: unquote('"\\e955"');
$np-deleted-file: unquote('"\\e915"');
$np-deleted-folder: unquote('"\\e929"');
$np-download: unquote('"\\e92a"');
$np-downloaded-file: unquote('"\\e92b"');
$np-downloads-folder: unquote('"\\e92c"');
$np-expand-arrows: unquote('"\\e92d"');
$np-file-cloud: unquote('"\\e92e"');
$np-file-done: unquote('"\\e92f"');
$np-file-minus: unquote('"\\e930"');
$np-file-plus: unquote('"\\e931"');
$np-file: unquote('"\\e932"');
$np-folder-check-1: unquote('"\\e933"');
$np-folder-check: unquote('"\\e934"');
$np-folder-error: unquote('"\\e935"');
$np-folder-heart: unquote('"\\e936"');
$np-folder-minus: unquote('"\\e937"');
$np-folder-plus: unquote('"\\e938"');
$np-folder-star: unquote('"\\e939"');
$np-folder: unquote('"\\e93a"');
$np-heart: unquote('"\\e93b"');
$np-locked-folder: unquote('"\\e93c"');
$np-other-1: unquote('"\\e93d"');
$np-other-2: unquote('"\\e93e"');
$np-size: unquote('"\\e93f"');
$np-star: unquote('"\\e940"');
$np-update: unquote('"\\e941"');
$np-upload-folder: unquote('"\\e942"');
$np-upload: unquote('"\\e943"');
$np-uploaded-file: unquote('"\\e944"');
$np-info: unquote('"\\e928"');
$np-warning-2: unquote('"\\e914"');
$np-clock: unquote('"\\e913"');
$np-warning1: unquote('"\\e912"');
$np-youtube: unquote('"\\e916"');
$np-user: unquote('"\\e917"');
$np-up-down: unquote('"\\e918"');
$np-right-circle: unquote('"\\e919"');
$np-minus: unquote('"\\e91a"');
$np-layout-horizontal: unquote('"\\e91b"');
$np-layout-4-blocks: unquote('"\\e91c"');
$np-error: unquote('"\\e91d"');
$np-done: unquote('"\\e91e"');
$np-contact-1: unquote('"\\e91f"');
$np-close: unquote('"\\e920"');
$np-clip: unquote('"\\e921"');
$np-chat-41: unquote('"\\e922"');
$np-calendar: unquote('"\\e923"');
$np-angle-up: unquote('"\\e924"');
$np-angle-right: unquote('"\\e925"');
$np-angle-left: unquote('"\\e926"');
$np-angle-down: unquote('"\\e927"');
$np-notifications-1: unquote('"\\e908"');
$np-selected-file: unquote('"\\e90c"');
$np-add-user: unquote('"\\e900"');
$np-chat-4: unquote('"\\e901"');
$np-edit-text: unquote('"\\e902"');
$np-edit: unquote('"\\e903"');
$np-filter: unquote('"\\e904"');
$np-font: unquote('"\\e905"');
$np-group-folders: unquote('"\\e906"');
$np-group: unquote('"\\e907"');
$np-plus: unquote('"\\e909"');
$np-question: unquote('"\\e90a"');
$np-search: unquote('"\\e90b"');
$np-sending: unquote('"\\e90d"');
$np-settings-2: unquote('"\\e90e"');
$np-sign-out: unquote('"\\e90f"');
$np-time-schedule: unquote('"\\e910"');
$np-trash: unquote('"\\e911"');

