@import './variables';

.color-primary-000 {
    color: $primary-color-brand000;
}
.color-primary-100 {
    color: $primary-color-brand100;
}
.color-primary-200 {
    color: $primary-color-brand200;
}
.color-primary-300 {
    color: $primary-color-brand300;
}
.color-primary-400 {
    color: $primary-color-brand400;
}
.color-primary-500 {
    color: $primary-color-brand500;
}
.color-primary-600 {
    color: $primary-color-brand600;
}
.color-primary-700 {
    color: $primary-color-brand700;
}
.color-primary-800 {
    color: $primary-color-brand800;
}

.color-primary-dark {
    color: $primary-color-brand-dark;
}
.color-primary-brand {
    color: $primary-color-brand;
}
.color-primary-light {
    color: $primary-color-brand-light;
}

//*******************SECONDARY COLOR*********************
.color-secondary-orange-dark {
    color: $secondary-color-orange-dark;
}
.color-secondary-orange {
    color: $secondary-color-orange;
}
.color-secondary-orange-light {
    color: $secondary-color-orange-light;
}
.color-secondary-alert-dark {
    color: $secondary-color-alert-dark;
}
.color-secondary-alert {
    color: $secondary-color-alert;
}
.color-secondary-alert-light {
    color: $secondary-color-alert-light;
}
.color-secondary-success-dark {
    color: $secondary-color-success-dark;
}
.color-secondary-success {
    color: $secondary-color-success;
}
.color-secondary-success-light {
    color: $secondary-color-success-light;
}
.color-secondary-yellow {
    color: $secondary-color-yellow;
}
.color-secondary-purple {
    color: $secondary-color-purple;
}
.color-secondary-purple-100 {
    color: $secondary-color-purple100;
}

.color-gray-000 {
    color: $color-gray000;
}
.color-gray-100 {
    color: $color-gray100;
}
.color-gray-200 {
    color: $color-gray200;
}
.color-gray-300 {
    color: $color-gray300;
}
.color-gray-400 {
    color: $color-gray400;
}
.color-gray-500 {
    color: $color-gray500;
}
.color-gray-600 {
    color: $color-gray600;
}