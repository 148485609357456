// .col-1,
// .col-2,
// .col-3,
// .col-4,
// .col-5,
// .col-6,
// .col-7,
// .col-8,
// .col-9,
// .col-10,
// .col-11,
// .col-12 {
//     padding-left: 0px;
//     padding-right: 0px;
// }

hr{
    margin-top: 0rem;
    margin-bottom: 0rem;
}