@import './variables';

.btn {
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-medium;
  line-height: $font-line-height-giant;
  padding: $spacing-xxsmall $spacing-xsmall;
  border-radius: $border-radius-4;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $color-gray300;
  background-color: $color-gray300;
  color: $color-gray100;

  &:hover {
    box-shadow: $button-shadow;
  }
  &:disabled {
    border: 1px solid transparent;
    // background-color: $color-gray300;
    box-shadow: none;
  }
  &:active,
  &:focus {  }
}

.btn-primary {
  border: 1px solid $primary-color-brand400;
  background-color: $primary-color-brand400;
  color: $color-gray000;
  &:disabled {
    background: $color-gray300;
  }
}

.btn-secondary {
  border: 1px solid $color-gray400;
  background-color: $color-gray000;
  color: $color-gray400;
}

.btn-danger {
  border: 1px solid $secondary-color-alert-dark;
  background-color: $secondary-color-alert;
  color: $color-gray000;
}

.btn-secondary-white{
  border: 1px solid $color-gray000;
  background-color: $color-gray000;
  color: $primary-color-brand400;
}

.btn-transparent-white-border {
  border: 1px solid $color-gray400;
  background: none;
  color: $color-gray400;
  &:hover {
    background-color: $color-gray000;
    border: none;
    box-shadow: none;
  }
}

.btn-secondary-yellow {
  border: none;
  background-color: $secondary-color-yellow;
  color: $color-gray600;
}
.btn-large {
  width: 56px;
  height: 82px;
  border: 1px solid $secondary-color-orange-dark;
  color: $secondary-color-orange-dark;
  background-color: $secondary-color-orange-light;
  padding: $spacing-xsmall $spacing-xxsmall;
}

.btn-secondary-white-border-blue {
  background-color: $color-gray000;
  color: $primary-color-brand300;
  border: 2px solid $primary-color-brand300;
  &:disabled {
    border: 2px solid $primary-color-brand300 !important;
    color: $primary-color-brand300 !important;
  }
}

.btn-icon {
  min-width: 34px;
  height: 34px;
  border-radius: $border-radius-4;
  font-weight: $font-weight-regular;
  font-size: $font-size-icon-medium;
  line-height: $font-line-height-giant;
  background-color: $color-gray000;
  color: $color-gray400;
  border: 1px solid $color-gray300;
  padding: $spacing-xxxsmall;

  &:hover {
    border: 1px solid $color-gray400;

    color: $color-gray400;
    box-shadow: $button-shadow;
  }
}

.btn-icon.hover-border-primary:hover {
  border: 1px solid $primary-color-brand400;
  color: $primary-color-brand400;
}

.btn-icon.hover-border-primary:disabled {
  border: 1px solid $color-gray200;
  color: $color-gray200;
}

.btn-hover-primary-border-color:hover {
  border: 1px solid $primary-color-brand400;
  color: $primary-color-brand400;
}

.btn-circle {
  border-radius: 100%;
  color: $color-gray000;
  background-color: $primary-color-brand200;
  border: 0px;
  width: 28px;
  height: 28px;
  box-shadow: $button-shadow;
}

.btn-icon-close {
  cursor: pointer;
  color:#636C74;
  font-weight: $font-weight-regular;
  font-size: $font-size-icon-giant;
  line-height: $font-line-height-giant;
  &:hover {
    color: black;
  }
}
.btn-ellipsis {
  height: 33px;
  width: 31px;
  padding: $spacing-xxsmall;
  border: 1px solid $color-gray400;
  border-radius: $border-radius-4;
  background-color: $color-gray000;
  &:hover {
    box-shadow: $button-shadow;
  }
}

.dropdown-menu {
  box-shadow: $button-shadow;
  border: 1px solid $color-gray200;
  padding: $spacing-xxsmall;
  border-radius: $border-radius-4;
  margin-top: $spacing-xxxsmall;
  content: none;
  width: auto;
  color: $color-gray400 !important;

  .dropdown-item {
    border: none;
    padding-inline-start: 0px;
    color: $color-gray400 !important;
    &:hover {
      border-radius: $border-radius-4;
      background-color: $primary-color-brand000;
    }

    &:focus {
      outline: none;
    }
    & i {
      color: $primary-color-brand400;
    }
  }
}

.dropdown-item:focus .np,
.dropdown-item:active .np {
  color: $color-gray400 !important;
}

.dropdown-toggle::after {
  content: none;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

@media (max-width: 768px) {
  .btn {
      font-size: 14px !important;
  }
}


.np-btn {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  // letter-spacing: .5px;
  cursor: pointer;

  &.primary {
      background-color: var(--np-color-primary-400);
      border: 1px solid var(--np-color-primary-400);
      color: white;

      &:hover {
          background-color: var(--np-color-primary-200) !important;
          border: 1px solid var(--np-color-primary-200);
          color: var(--np-color-primary-400);
      }
  }

  &.success {
      background-color: var(--np-color-success-300);
      border: 1px solid var(--np-color-success-300);
      color: white;

      &:hover {
          background-color: var(--np-color-success-100);
          border: 1px solid var(--np-color-success-100);
          color: var(--np-color-success-300);
      }
  }

  &.alert {
      background-color: var(--np-color-alert-300);
      border: 1px solid var(--np-color-alert-300);
      color: white;
      margin-bottom: 0px;

      &:hover {
          background-color: var(--np-color-alert-100);
          border: 1px solid var(--np-color-alert-100);
          color: var(--np-color-alert-300);
      }
  }

  &.danger {
      background-color: var(--np-color-danger-300);
      border: 1px solid var(--np-color-danger-300);
      color: white;

      &:hover {
          background-color: var(--np-color-danger-100);
          border: 1px solid var(--np-color-danger-100);
          color: var(--np-color-danger-300);
      }
  }

  &.system {
      background-color: var(--np-color-system-400);
      border: 1px solid var(--np-color-system-400);
      color: white;

      &:hover {
          background-color: var(--np-color-system-200);
          border: 1px solid var(--np-color-system-200);
          color: var(--np-color-system-400);
      }
  }

  &.primary-outline {
      background-color: white;
      border: 1px solid var(--np-color-primary-400);
      color: var(--np-color-primary-400);

      &:hover {
          background-color: var(--np-color-primary-400);
          border: 1px solid var(--np-color-primary-400);
          color: white;
      }
  }

  &.system-outline {
      background-color: white;
      border: 1px solid var(--np-color-system-400);
      color: var(--np-color-system-400);

      &:hover {
          background-color: var(--np-color-system-400);
          border: 1px solid var(--np-color-system-400);
          color: white;
      }
  }

  &.success-outline {
      background-color: white;
      border: 1px solid var(--np-color-success-300);
      color: var(--np-color-success-300);

      &:hover {
          background-color: var(--np-color-success-300);
          border: 1px solid var(--np-color-success-300);
          color: white;
      }
  }

  &.alert-outline {
      background-color: white;
      border: 1px solid var(--np-color-alert-300);
      color: var(--np-color-alert-300);

      &:hover {
          background-color: var(--np-color-alert-300);
          border: 1px solid var(--np-color-alert-300);
          color: white;
      }
  }

  &.danger-outline {
      background-color: white;
      border: 1px solid var(--np-color-danger-300);
      color: var(--np-color-danger-300);

      &:hover {
          background-color: var(--np-color-danger-300);
          border: 1px solid var(--np-color-danger-300);
          color: white;
      }
  }

  &:disabled {
      border-color: 1px solid var(--np-color-system-300) !important;
      background-color: var(--np-color-system-300) !important;
      color: white !important;
      cursor: not-allowed !important;

      &:hover {
          border-color: 1px solid var(--np-color-system-300) !important;
          background-color: var(--np-color-system-300) !important;
          color: white !important;
          cursor: not-allowed !important;
      }
  }

  np-icones{
    display: inline-flex;
    align-items: center;
  }
}