@import './assets/sass/colors';
@import './assets/sass/bootstrap-override';
@import './assets/sass/buttons';
@import './assets/sass/modal';

@font-face {
  font-family: inter;
  src: url(./assets/fonts/Inter-Regular.ttf);
}

@font-face {
  font-family: kalam;
  src: url(./assets/fonts//Kalam-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: italianno;
  src: url(./assets/fonts/Italianno-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: kaufmannbt;
  src: url(./assets/fonts/kaufmann-bt.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

.ff-kalam {
  font-family: kalam;
}

.ff-italianno {
  font-family: italianno;
}

.ff-kaufmannbt {
  font-family: kaufmannbt;
}

* {
  // margin: 0;
  // padding: 0;
  // outline: 0;
  box-sizing: border-box;
  font-family: Inter;
}

html,
body {
  min-height: 100%;
  height: 100%;
  background-color: #eeeef6;
}

/* style icon */
.inner-addon {
  position: relative;
}

.inner-addon .np {
  position: absolute;
  padding: 10.5px;
  pointer-events: none;
}

/* align icon */
.left-addon .np {
  left: 0px;
}

.right-addon .np {
  right: 0px;
}

/* add padding  */
.left-addon input {
  padding-left: 30px !important;
}

.right-addon input {
  padding-right: 30px !important;
}

.inner-addon > .np-done {
  color: #60c02b;
}

.inner-addon > .np-warning1 {
  color: #ed2e3b;
}

.inner-addon > .np-mail-locked {
  color: #B9C0C7;
}

.two-addon-in-same-input {
  :last-child {
    cursor: pointer !important;
    left: auto !important;
    right: 0px !important;
  }
}

/* datepicker*/
ngb-datepicker {
  left: 0px !important;
  top: 4px !important;
  text-transform: capitalize;

  .custom-select {
    text-transform: capitalize;

    &:first-of-type {
      margin-right: 7px;
    }
  }

  .ngb-dp-weekday {
    color: #43494f !important;
    text-decoration: none;
    font-style: normal;
  }

  .ngb-dp-navigation-chevron {
    color: #43494f !important;
    border-radius: 2px;
  }

  .bg-primary {
    background-color: #334eae !important;
  }
}

/* Tooltip  */

.btn-tooltip .tooltip-inner {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  padding: 2px 10px;
  background-color: rgba(26, 30, 44, 0.8);
  max-width: 105px;
}

.btn-tooltip .arrow::before {
  border-top-color: rgba(26, 30, 44, 0.8) !important;
}

/* Scroll */
* {
  scrollbar-width: thin;
  scrollbar-color: #b9c0c7 #dfe2e6;
}

/* Scroll on Chrome, Edge, and Safari */

*::-webkit-scrollbar {
  width: 7px;
  &:horizontal {
    height: 7px;
  }
}

*::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255, 0);
  border-radius: 4px;
  width: 7px;
}

*::-webkit-scrollbar-thumb {
  background-color: #b9c0c7;
  border-radius: 4px;
  width: 7px;
}

*::-webkit-scrollbar-track-piece {
  border-radius: 4px;
  background: #dfe2e6;
  margin-block: 5px;
}

.scroll-content-page {
  overflow: auto;
  height: 92%;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.cursor-pointer {
  cursor: pointer;
}


// np-icones {
//   display: inline-flex;
//   align-items: center;
// }