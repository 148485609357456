//*******************COLORS*********************

$custom-colors: (
  brand000: #f7f7fa,
  brand050: #EEEEF6,
  brand100: #ccd3eb,
  brand200: #99a7d7,
  brand300: #667ac2,
  brand400: #334eae,
  brand500: #00229a,
  brand600: #001b7b,
  brand700: #00145c,
  brand800: #1736A4,
  orange100: #ffeab3,
  orange200: #f8ae3f,
  orange300: #f49f1f,
  red100: #fbd5d8,
  red200: #ed2e3b,
  red300: #ce0714,
  green100: #cce7d7,
  green200: #60c02b,
  green300: #4f9627,
  yellow100: #ffcc40,
  purple100: #6e5fc8,
  purple200: #4861B7,
  gray000: #ffffff,
  gray100: #f7f9fa,
  gray200: #dfe2e6,
  gray300: #b9c0c7,
  gray400: #636c74,
  gray500: #43494f,
  gray600: #212529,
);
//*******************PRIMARY COLOR*********************

$primary-color-brand000: map-get($custom-colors, brand000);
$primary-color-brand050: map-get($custom-colors, brand050);
$primary-color-brand100: map-get($custom-colors, brand100);
$primary-color-brand200: map-get($custom-colors, brand200);
$primary-color-brand300: map-get($custom-colors, brand300);
$primary-color-brand400: map-get($custom-colors, brand400);
$primary-color-brand500: map-get($custom-colors, brand500);
$primary-color-brand600: map-get($custom-colors, brand600);
$primary-color-brand700: map-get($custom-colors, brand700);
$primary-color-brand800: map-get($custom-colors, brand800);
$primary-color-brand-dark: map-get($custom-colors, brand400);
$primary-color-brand: map-get($custom-colors, brand200);
$primary-color-brand-light: map-get($custom-colors, brand100);

//*******************SECONDARY COLOR*********************
$secondary-color-orange-light: map-get($custom-colors, orange100);
$secondary-color-orange: map-get($custom-colors, orange200);
$secondary-color-orange-dark: map-get($custom-colors, orange300);

$secondary-color-alert-light: map-get($custom-colors, red100);
$secondary-color-alert: map-get($custom-colors, red200);
$secondary-color-alert-dark: map-get($custom-colors, red300);

$secondary-color-success-light: map-get($custom-colors, green100);
$secondary-color-success: map-get($custom-colors, green200);
$secondary-color-success-dark: map-get($custom-colors, green300);

$secondary-color-yellow: map-get($custom-colors, yellow100);

$secondary-color-purple: map-get($custom-colors, purple100);
$secondary-color-purple100: map-get($custom-colors, purple200);

//*******************GRAY COLOR *********************
$color-gray000: map-get($custom-colors, gray000);
$color-gray100: map-get($custom-colors, gray100);
$color-gray200: map-get($custom-colors, gray200);
$color-gray300: map-get($custom-colors, gray300);
$color-gray400: map-get($custom-colors, gray400);
$color-gray500: map-get($custom-colors, gray500);
$color-gray600: map-get($custom-colors, gray600);

//********************FONTS**********************
$text-color: $color-gray500;
$text-color-2: $color-gray000;

//**WEIGHT**
$font-weight-bold: 700;
$font-weight-semi-bold: 600;
$font-weight-regular: 400;

//**SIZE**
$font-size-display: 54px;
$font-size-huge: 46px;
$font-size-giant: 38px;
$font-size-big: 30px;
$font-size-xx-big: 96px;
$font-size-xx-large: 24px;
$font-size-xy-large: 21px;
$font-size-x-large: 20px;
$font-size-large: 16px;
$font-size-medium: 14px;
$font-size-small: 12px;
$font-size-tiny: 10px;

//**LINE**
$font-line-height-medium: 100%;
$font-line-height-large: 120%;
$font-line-height-giant: 150%;

//**ICON TEXTS**
$font-size-icon-display: 38px;
$font-size-icon-huge: 34px;
$font-size-icon-giant: 30px;
$font-size-icon-big: 26px;
$font-size-icon-24: 24px;
$font-size-icon-large: 22px;
$font-size-icon-medium: 18px;
$font-size-icon-small: 14px;
$font-size-icon-tiny: 12px;

//********************FONTS**********************

//****************BORDER-RADIUS******************
$border-radius-24: 24px;
$border-radius-16: 16px;
$border-radius-8: 8px;
$border-radius-4: 4px;

//****************BORDER-RADIUS******************

//*******************SPACING*********************
$spacing-xxxsmall: 4px;
$spacing-xxsmall: 8px;
$spacing-xsmall: 16px;
$spacing-medium: 24px;
$spacing-large: 32px;
$spacing-xlarge: 40px;
$spacing-xxlarge: 48px;
$spacing-xxxlarge: 56px;
$spacing-huge: 64px;
$spacing-xhuge: 72px;
$spacing-giant: 80px;

//*******************SPACING*********************

//*******************SHADOWS*********************

$button-shadow: 0px 2px 3px rgba($color-gray600, 0.25);
$alert-shadow: 0px 10px 20px rgba($color-gray600, 0.1);
$card-shadow: 2px 2px 4px rgba($color-gray600, 0.25);
$modal-shadow: 0px 10px 50px rgba($color-gray600, 0.25);

//*******************SHADOWS*********************

//*******************TEMPLATE********************
$default-auth-nav-heigth: 7.6vh;
$default-nav-mobile-menu: 102px;
//*******************TEMPLATE********************

$mobile-min-width: 360px;
$mobile-max-width: 576px;
$tablet-min-width: calc(var($mobile-max-width) + 1px);
$tablet-max-width: 992px;
$desktop-min-width: calc(var($tablet-max-width) + 1px);

.text-color-primary-brand-000 {
  color: $primary-color-brand000 !important;
}

.text-color-primary-brand-050 {
  color: $primary-color-brand050 !important;
}

.text-color-primary-brand-100 {
  color: $primary-color-brand100 !important;
}

.text-color-primary-brand-200 {
  color: $primary-color-brand200 !important;
}

.text-color-primary-brand-300 {
  color: $primary-color-brand300 !important;
}

.text-color-primary-brand-400 {
  color: $primary-color-brand400 !important;
}

.text-color-primary-brand-500 {
  color: $primary-color-brand500 !important;
}

.text-color-primary-brand-600 {
  color: $primary-color-brand600 !important;
}

.text-color-primary-brand-700 {
  color: $primary-color-brand700 !important;
}

.text-color-primary-brand-800 {
  color: $primary-color-brand800 !important;
}

.text-color-primary-brand-dark {
  color: $primary-color-brand-dark !important;
}

.text-color-primary-brand {
  color: $primary-color-brand !important;
}

.text-color-primary-brand-light {
  color: $primary-color-brand-light !important;
}

.text-color-secondary-orange-dark {
  color: $secondary-color-orange-dark !important;
}

.text-color-secondary-orange {
  color: $secondary-color-orange !important;
}

.text-color-secondary-orange-light {
  color: $secondary-color-orange-light !important;
}

.text-color-secondary-alert-dark {
  color: $secondary-color-alert-dark !important;
}

.text-color-secondary-alert {
  color: $secondary-color-alert !important;
}

.text-color-secondary-alert-light {
  color: $secondary-color-alert-light !important;
}

.text-color-secondary-success-dark {
  color: $secondary-color-success-dark !important;
}

.text-color-secondary-success {
  color: $secondary-color-success !important;
}

.text-color-secondary-success-light {
  color: $secondary-color-success-light !important;
}

.text-color-secondary-yellow {
  color: $secondary-color-yellow !important;
}

.text-color-secondary-purple {
  color: $secondary-color-purple !important;
}

.text-color-secondary-purple-100 {
  color: $secondary-color-purple100 !important;
}

.text-color-gray-000 {
  color: $color-gray000 !important;
}

.text-color-gray-100 {
  color: $color-gray100 !important;
}

.text-color-gray-200 {
  color: $color-gray200 !important;
}

.text-color-gray-300 {
  color: $color-gray300 !important;
}

.text-color-gray-400 {
  color: $color-gray400 !important;
}

.text-color-gray-500 {
  color: $color-gray500 !important;
}

.text-color-gray-600 {
  color: $color-gray600 !important;
}

:root {
  --np-color-primary-000: #{map-get($custom-colors,brand000)};
  --np-color-primary-050: #{map-get($custom-colors,brand050)};
  --np-color-primary-100: #{map-get($custom-colors,brand100)};
  --np-color-primary-200: #{map-get($custom-colors,brand200)};
  --np-color-primary-300: #{map-get($custom-colors,brand300)};
  --np-color-primary-400: #{map-get($custom-colors,brand400)};
  --np-color-primary-500: #{map-get($custom-colors,brand500)};
  --np-color-primary-600: #{map-get($custom-colors,brand600)};
  --np-color-primary-700: #{map-get($custom-colors,brand700)};
  --np-color-primary-800: #{map-get($custom-colors,brand800)};
  --np-color-alert-100: #{map-get($custom-colors, orange100)};
  --np-color-alert-200: #{map-get($custom-colors, orange200)};
  --np-color-alert-300: #{map-get($custom-colors, orange300)};
  --np-color-danger-100: #{map-get($custom-colors, red100)};
  --np-color-danger-200: #{map-get($custom-colors, red200)};
  --np-color-danger-300: #{map-get($custom-colors, red300)};
  --np-color-success-100: #{map-get($custom-colors, green100)};
  --np-color-success-200: #{map-get($custom-colors, green200)};
  --np-color-success-300: #{map-get($custom-colors, green300)};
  --np-color-alternative-yellow-100: #{map-get($custom-colors, yellow100)};
  --np-color-alternative-purple-100: #{map-get($custom-colors, purple100)};
  --np-color-alternative-purple-200: #{map-get($custom-colors, purple200)};
  --np-color-system-000: #{map-get($custom-colors,gray000)};
  --np-color-system-100: #{map-get($custom-colors,gray100)};
  --np-color-system-200: #{map-get($custom-colors,gray200)};
  --np-color-system-300: #{map-get($custom-colors,gray300)};
  --np-color-system-400: #{map-get($custom-colors,gray400)};
  --np-color-system-500: #{map-get($custom-colors,gray500)};
  --np-color-system-600: #{map-get($custom-colors,gray600)};
}


