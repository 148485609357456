@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('./../#{$icomoon-font-path}/#{$icomoon-font-family}.eot?o69ehh');
  src: url('./../#{$icomoon-font-path}/#{$icomoon-font-family}.eot?o69ehh#iefix') format('embedded-opentype'),
  url('./../#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?o69ehh') format('truetype'),
  url('./../#{$icomoon-font-path}/#{$icomoon-font-family}.woff?o69ehh') format('woff'),
  url('./../#{$icomoon-font-path}/#{$icomoon-font-family}.svg?o69ehh##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="np-"],
[class*=" np-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.np-np_file_empy {
  &:before {
    content: $np-np_file_empy;

  }
}

.np-np_file_check {
  &:before {
    content: $np-np_file_check;

  }
}

.np-np_file_double_check {
  &:before {
    content: $np-np_file_double_check;

  }
}

.np-filter1 {
  &:before {
    content: $np-filter1;
    color: #636c74;
  }
}

.np-adm {
  &:before {
    content: $np-adm;

  }
}

.np-help {
  &:before {
    content: $np-help;

  }
}

.np-edit-user {
  &:before {
    content: $np-edit-user;

  }
}

.np-file-view {
  &:before {
    content: $np-file-view;

  }
}

.np-hourglass {
  &:before {
    content: $np-hourglass;

  }
}

.np-add {
  &:before {
    content: $np-add;

  }
}

.np-Icon-file {
  &:before {
    content: $np-Icon-file;

  }
}

.np-New-doc {
  &:before {
    content: $np-New-doc;

  }
}

.np-done-line {
  &:before {
    content: $np-done-line;

  }
}

.np-close-doc {
  &:before {
    content: $np-close-doc;
    color: #636c74;
  }
}

.np-company {
  &:before {
    content: $np-company;

  }
}

.np-menu-duo {
  &:before {
    content: $np-menu-duo;

  }
}

.np-menu-trio {
  &:before {
    content: $np-menu-trio;
  }
}

.np-eletronic-signature {
  &:before {
    content: $np-eletronic-signature;
  }
}

.np-digital-signature {
  &:before {
    content: $np-digital-signature;
  }
}

.np-arrow-right {
  &:before {
    content: $np-arrow-right;
  }
}

.np-move-folder {
  &:before {
    content: $np-move-folder;
  }
}

.np-arrow-left {
  &:before {
    content: $np-arrow-left;
  }
}

.np-share-2 {
  &:before {
    content: $np-share-2;
  }
}

.np-share {
  &:before {
    content: $np-share;
  }
}

.np-user-folder {
  &:before {
    content: $np-user-folder;
  }
}

.np-tag {
  &:before {
    content: $np-tag;
  }
}

.np-duplicate {
  &:before {
    content: $np-duplicate;
  }
}

.np-edit-2 {
  &:before {
    content: $np-edit-2;
  }
}

.np-incoming-box {
  &:before {
    content: $np-incoming-box;
  }
}

.np-incoming-mail {
  &:before {
    content: $np-incoming-mail;
  }
}

.np-locked {
  &:before {
    content: $np-locked;
  }
}

.np-mail-box {
  &:before {
    content: $np-mail-box;
  }
}

.np-mail-locked {
  &:before {
    content: $np-mail-locked;
  }
}

.np-mail-unlocked {
  &:before {
    content: $np-mail-unlocked;
  }
}

.np-outgoing-box {
  &:before {
    content: $np-outgoing-box;
  }
}

.np-outgoing-mail {
  &:before {
    content: $np-outgoing-mail;
  }
}

.np-unlocked {
  &:before {
    content: $np-unlocked;
  }
}

.np-stamp {
  &:before {
    content: $np-stamp;
  }
}

.np-snoozed-mail {
  &:before {
    content: $np-snoozed-mail;
  }
}

.np-sending-mail {
  &:before {
    content: $np-sending-mail;
  }
}

.np-readed-mail {
  &:before {
    content: $np-readed-mail;
  }
}

.np-printer {
  &:before {
    content: $np-printer;
  }
}

.np-deleted-file {
  &:before {
    content: $np-deleted-file;
  }
}

.np-deleted-folder {
  &:before {
    content: $np-deleted-folder;
  }
}

.np-download {
  &:before {
    content: $np-download;
  }
}

.np-downloaded-file {
  &:before {
    content: $np-downloaded-file;
  }
}

.np-downloads-folder {
  &:before {
    content: $np-downloads-folder;
  }
}

.np-expand-arrows {
  &:before {
    content: $np-expand-arrows;
  }
}

.np-file-cloud {
  &:before {
    content: $np-file-cloud;
  }
}

.np-file-done {
  &:before {
    content: $np-file-done;
  }
}

.np-file-minus {
  &:before {
    content: $np-file-minus;
  }
}

.np-file-plus {
  &:before {
    content: $np-file-plus;
  }
}

.np-file {
  &:before {
    content: $np-file;
  }
}

.np-folder-check-1 {
  &:before {
    content: $np-folder-check-1;
  }
}

.np-folder-check {
  &:before {
    content: $np-folder-check;
  }
}

.np-folder-error {
  &:before {
    content: $np-folder-error;
  }
}

.np-folder-heart {
  &:before {
    content: $np-folder-heart;
  }
}

.np-folder-minus {
  &:before {
    content: $np-folder-minus;
  }
}

.np-folder-plus {
  &:before {
    content: $np-folder-plus;
  }
}

.np-folder-star {
  &:before {
    content: $np-folder-star;
  }
}

.np-folder {
  &:before {
    content: $np-folder;
  }
}

.np-heart {
  &:before {
    content: $np-heart;
  }
}

.np-locked-folder {
  &:before {
    content: $np-locked-folder;
  }
}

.np-other-1 {
  &:before {
    content: $np-other-1;
  }
}

.np-other-2 {
  &:before {
    content: $np-other-2;
  }
}

.np-size {
  &:before {
    content: $np-size;
  }
}

.np-star {
  &:before {
    content: $np-star;
  }
}

.np-update {
  &:before {
    content: $np-update;
  }
}

.np-upload-folder {
  &:before {
    content: $np-upload-folder;
  }
}

.np-upload {
  &:before {
    content: $np-upload;
  }
}

.np-uploaded-file {
  &:before {
    content: $np-uploaded-file;
  }
}

.np-info {
  &:before {
    content: $np-info;
  }
}

.np-warning-2 {
  &:before {
    content: $np-warning-2;
  }
}

.np-clock {
  &:before {
    content: $np-clock;
  }
}

.np-warning1 {
  &:before {
    content: $np-warning1;
  }
}

.np-youtube {
  &:before {
    content: $np-youtube;
  }
}

.np-user {
  &:before {
    content: $np-user;
  }
}

.np-up-down {
  &:before {
    content: $np-up-down;
  }
}

.np-right-circle {
  &:before {
    content: $np-right-circle;
  }
}

.np-minus {
  &:before {
    content: $np-minus;
  }
}

.np-layout-horizontal {
  &:before {
    content: $np-layout-horizontal;
  }
}

.np-layout-4-blocks {
  &:before {
    content: $np-layout-4-blocks;
  }
}

.np-error {
  &:before {
    content: $np-error;
  }
}

.np-done {
  &:before {
    content: $np-done;
  }
}

.np-contact-1 {
  &:before {
    content: $np-contact-1;
  }
}

.np-close {
  &:before {
    content: $np-close;
  }
}

.np-clip {
  &:before {
    content: $np-clip;
  }
}

.np-chat-41 {
  &:before {
    content: $np-chat-41;
  }
}

.np-calendar {
  &:before {
    content: $np-calendar;
  }
}

.np-angle-up {
  &:before {
    content: $np-angle-up;
  }
}

.np-angle-right {
  &:before {
    content: $np-angle-right;
  }
}

.np-angle-left {
  &:before {
    content: $np-angle-left;
  }
}

.np-angle-down {
  &:before {
    content: $np-angle-down;
  }
}

.np-notifications-1 {
  &:before {
    content: $np-notifications-1;
  }
}

.np-selected-file {
  &:before {
    content: $np-selected-file;
  }
}

.np-add-user {
  &:before {
    content: $np-add-user;
  }
}

.np-chat-4 {
  &:before {
    content: $np-chat-4;
  }
}

.np-edit-text {
  &:before {
    content: $np-edit-text;
  }
}

.np-edit {
  &:before {
    content: $np-edit;
  }
}

.np-filter {
  &:before {
    content: $np-filter;
  }
}

.np-font {
  &:before {
    content: $np-font;
  }
}

.np-group-folders {
  &:before {
    content: $np-group-folders;
  }
}

.np-group {
  &:before {
    content: $np-group;
  }
}

.np-plus {
  &:before {
    content: $np-plus;
  }
}

.np-question {
  &:before {
    content: $np-question;
  }
}

.np-search {
  &:before {
    content: $np-search;
  }
}

.np-sending {
  &:before {
    content: $np-sending;
  }
}

.np-settings-2 {
  &:before {
    content: $np-settings-2;
  }
}

.np-sign-out {
  &:before {
    content: $np-sign-out;
  }
}

.np-time-schedule {
  &:before {
    content: $np-time-schedule;
  }
}

.np-trash {
  &:before {
    content: $np-trash;
  }
}